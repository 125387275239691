<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Страницы сайта</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Каталог</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model :model="block">
          <a-form-model-item label="Название(ua)" required>
            <a-input  v-model.number="block.ua.name" />
          </a-form-model-item>
          <a-form-model-item label="Описание(ua)" required>
            <a-input  v-model.number="block.ua.description" />
          </a-form-model-item>
          <a-form-model-item label="Название(ru)" required>
            <a-input  v-model.number="block.ru.name" />
          </a-form-model-item>
          <a-form-model-item label="Описание(ru)" required>
            <a-input  v-model.number="block.ru.description" />
          </a-form-model-item>
        </a-form-model>
        <a-button class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          Сохранить изменения
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      block: {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
    }
  },
  computed: {
    isDisabled() {
      const block = this.block
      return !(block.ru.name && block.ru.description && block.ua.description && block.ua.name)
    },
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      try {
        this.block = (await this.$services.get('/admin/catalog')).data.data.data.block_pages[0]
      } catch (e) {
        console.log(e)
      }
    },

    async submit() {
      try {
        await this.$services.put(`admin/block_pages/${this.block.id}`, this.block)
        this.$router.back()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
